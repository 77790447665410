import * as React from 'react'
import { Box, Typography, Grid, Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import WidgetFooterButton from '../WidgetFooterButton'
import crStyles from '../crStyles'

// this was the static events page before we were pulling from engage
const CRevents = ({ handleClick, data }) => {
  const classes = crStyles()
  const theme = useTheme()
  console.log(1234, data)
  return (
    <Box>
      <Box className={classes.eventsHeaderContainer}>
        <Typography variant="h5">
          <b>{data.title}</b>
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.aboutUsTextContainer}
      >
        <Grid item style={{ textAlign: 'center' }}>
          <div className={classes.eventsImageDiv}>
            {data.imageFinal && (
              <img
                src={data.imageFinal}
                alt={data.imageAltText}
                height="100%"
              />
            )}
          </div>
        </Grid>
        <Grid item>
          <Typography variant="body1">{data.description}</Typography>
        </Grid>
        <Grid item className={classes.eventsCalendarButtonContainer}>
          <WidgetFooterButton
            title={data.buttonText}
            bgColor={theme.palette.components.rcsaBlue}
            handleClick={() => handleClick(data.buttonLink)} // TODO:
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default CRevents
