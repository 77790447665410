/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sectionMap, twitterSource } from './constants'
import {
  mapSources,
  fetchUserNewsPreferences,
  sortNewsArticlesIntoSections,
  checkIfUserHasAllSectionsOrderAndCreateThemIfTheyDont,
  fetchUserLocationCode,
  formatFacebookArticle,
  //   formatTweet,
} from './helpers'

const initialState = {
  // State related to news objects
  news: {},
  sources: [],
  allArticles: [],
  unsubscribedSources: [],
  restArticlesIndex: 30,

  // Search Bar Related State
  displaySearchBar: false,
  displaySearchItems: false,
  searchBarList: [],
  searchLoading: false,
  search: {
    enableSearch: false,
    searchQuery: '',
    articleList: [],
    loading: false,
  },

  // Modal State
  newsReorderDialogOpen: false,
  newsSourcesSubscriptionDialogOpen: false,

  // State related items
  newsStatus: 'idle',
  sourcesStatus: 'idle',
}

export const fetchSources = createAsyncThunk('fetchSources', async () => {
  const sourcesResponse = await axios.get(
    process.env.REACT_APP_NEWS_SOURCES_ENDPOINT,
  )
  return [...sourcesResponse.data, twitterSource]
})

export const fetchNews = createAsyncThunk('fetchNews', async () => {
  const newsResponse = await axios.get(process.env.REACT_APP_NEWS_ENDPOINT)
  //   const tweetsResponse = await axios.get(
  //     process.env.REACT_APP_NEWS_TWEETS_ENDPOINT,
  //   )

  const userData = await fetchUserNewsPreferences()

  return {
    newsData: newsResponse.data,
    // tweetsData: tweetsResponse.data,
    userData,
  }
})

export const fetchSocialMediaLocationForArticles = createAsyncThunk(
  'fetchSocialMediaLocationForArticles',
  async () => {
    const userLocationCode = await fetchUserLocationCode()
    return userLocationCode
  },
)

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action) => ({
      ...state,
      news: action.payload,
    }),

    // Methods related to search
    displaySearchBar: (state) => ({
      ...state,
      displaySearchBar: !state.displaySearchBar,
    }),

    // Methods related to search
    updateSearchBarContents: (state, action) => ({
      ...state,
      search: {
        enableSearch: action.payload.enableSearch,
        searchQuery: action.payload.searchQuery,
        articleList: action.payload.articleList,
        loading: action.payload.loading,
      },
    }),
    updateSearchLoading: (state, action) => ({
      ...state,
      searchLoading: action.payload,
    }),
    updateRestArticlesIndex: (state) => {
      state.restArticlesIndex += 30
    },

    // Methods related to user settings
    setOpenNewsReorderDialog: (state, action) => ({
      ...state,
      newsReorderDialogOpen: action.payload,
    }),
    setOpenNewsSourcesSubscriptionDialogOpen: (state, action) => ({
      ...state,
      newsSourcesSubscriptionDialogOpen: action.payload,
    }),
    updateUnsubscribedSources: (state, action) => ({
      ...state,
      unsubscribedSources: action.payload,
    }),
  },
  extraReducers: {
    [fetchSources.rejected]: (state) => {
      state.sourcesStatus = 'error'
    },
    [fetchSources.pending]: (state) => {
      state.sourcesStatus = 'pending'
    },
    [fetchSources.fulfilled]: (state, action) => {
      state.sources = mapSources(action.payload)
      state.sourcesStatus = 'fulfilled'
    },
    [fetchNews.rejected]: (state) => {
      state.newsStatus = 'error'
    },
    [fetchNews.pending]: (state) => {
      state.newsStatus = 'pending'
    },
    [fetchNews.fulfilled]: (state, action) => {
      // Articles, section order all get added into this object that eventually gets set in state.news
      const newsArticlesSortedBySection = {
        // id: 0,
        // section: ex: Rutgers Today, For You, Most Viewed,
        // articles: []
        // order: 0
        // hidden: false
        // tag: ex: rutgersToday, you, most
      }
      // Data from endpoint
      const {
        newsData: newsEndpointResponse,
        // tweetsData: { tweets: tweetsEndpointResponse },
        userData: { ignoreSources, sections },
      } = action.payload
      // Append twitter data only if tweetsEndpointResponse is defined and not null
      //   if (tweetsEndpointResponse) {
      //     newsEndpointResponse.push(
      //       ...Object.values(tweetsEndpointResponse).map((tweet) =>
      //         formatTweet(tweet),
      //       ),
      //     )
      //   }

      // Take all articles and partition by section(tag) returned from API
      const sortedArticles = sortNewsArticlesIntoSections(
        newsEndpointResponse,
        state.sources,
      )

      // Check if users section is missing any sections and add them
      const updatedSections =
        checkIfUserHasAllSectionsOrderAndCreateThemIfTheyDont(
          new Set(sections),
          sortedArticles,
        )
      // Creating the newsArticlesSortedBySection object
      updatedSections.forEach((section, index) => {
        // Iterate through sections since it contains the master list of tags
        const sectionName = sectionMap[section] || section

        const articles = sortedArticles[section] || []

        newsArticlesSortedBySection[section] = {
          id: index,
          tag: section,
          humanReadableSectionName: sectionName,
          articles,
          order: index,
          hidden: articles.length === 0,
        }
      })
      // add facebook articles to social section based on locationCodeSID
      newsArticlesSortedBySection.social.articles =
        newsEndpointResponse?.filter(
          (newsArticles) =>
            newsArticles.sid === state.locationCodeSID ||
            newsArticles.sid === 46,
        )
      newsArticlesSortedBySection.social.articles =
        newsArticlesSortedBySection.social?.articles
          ?.slice(0, 5)
          .map((article) => formatFacebookArticle(article))
      console.log('newsArticlesSortedBySection ', newsArticlesSortedBySection)
      // Set rest of items to state
      state.news = newsArticlesSortedBySection
      state.unsubscribedSources = ignoreSources
      state.allArticles = [
        ...newsArticlesSortedBySection.feature.articles,
        ...newsArticlesSortedBySection.most.articles,
        ...newsArticlesSortedBySection.rest.articles,
        ...newsArticlesSortedBySection.rutgersToday.articles,
        ...newsArticlesSortedBySection.social.articles,
        ...newsArticlesSortedBySection.top.articles,
        ...newsArticlesSortedBySection.trend.articles,
        ...newsArticlesSortedBySection.you.articles,
      ]
      state.newsStatus = 'fulfilled'
    },
    [fetchSocialMediaLocationForArticles.rejected]: (state) => {
      state.locationCodeSIDStatus = 'error'
    },
    [fetchSocialMediaLocationForArticles.pending]: (state) => {
      state.locationCodeSIDStatus = 'pending'
    },
    [fetchSocialMediaLocationForArticles.fulfilled]: (state, action) => {
      let locationCodeSID
      switch (action.payload) {
        case '1':
          locationCodeSID = 41
          break
        case '2':
          locationCodeSID = 42
          break
        case '3':
          locationCodeSID = 43
          break
        case '4':
        case '5':
          locationCodeSID = 44
          break
        default:
          locationCodeSID = 41
      }
      state.locationCodeSID = locationCodeSID
      state.locationCodeSIDStatus = 'fulfilled'
    },
  },
})

export const {
  setNews,
  displaySearchBar,
  updateSearchBarContents,
  updateSearchLoading,
  setOpenNewsReorderDialog,
  setOpenNewsSourcesSubscriptionDialogOpen,
  showSearchBarContents,
  updateRestArticlesIndex,
  updateUnsubscribedSources,
} = newsSlice.actions

export default newsSlice.reducer
