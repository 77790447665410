import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Badge,
  Button,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Tooltip,
} from '@material-ui/core'

import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import NotificationsIcon from '@material-ui/icons/Notifications'

import SearchDialog from '../../sideNav/customButtons/SearchDialog'
import SearchDialogMobileView from '../../sideNav/customButtons/SearchDialogMobileView'

import { alertsOpen, alertsClose } from '../../navigationSlice'
import { addProfileLogEvent } from '../../../features/dashboard/widgets/MyProfile/myProfileSlice'
import {
  fetchMyAlerts,
  focusAlert,
  acknowledgeAlert,
  selectMyAlertsData,
} from '../../../features/dashboard/widgets/MyAlerts/MyAlertsSlice'

import AlertGUI from '../../../features/dashboard/widgets/MyAlerts/Components/AlertGUI'
import { toggleShowSensitiveInfo } from '../../../features/settings/settingsSlice'
import { handleLogoutClick } from '../../../features/settings/pages/Main'
import ManageWidgets from '../../../features/dashboard/DashboardComponents/ManageWidgets/ManageWidgets'

export const LogOutButton = ({ classes, dispatch, userProfile, isMobile }) => {
  const handleLogoutClickHandler = () => {
    handleLogoutClick(dispatch, userProfile)
  }
  return (
    <>
      {isMobile === false && (
        <Grid item>
          <Tooltip
            title={<Typography variant="subtitle2">Logout</Typography>}
            placement="top"
            arrow
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Log out"
              onClick={handleLogoutClickHandler}
            >
              <ExitToAppIcon className={classes.appBarIcon} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </>
  )
}

// TODO Add back sensitive info toggle (Should do with login updates)
export default function DashboardLocalButtons(
  classes,
  history,
  dispatch,
  showSensitiveInfo,
  isMobile,
  dialogOpen,
  activeAlerts,
  userProfile,
) {
  // const his = useHistory()
  const handlePrivacyClick = () => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'TopAppBar',
          object: 'TopAppBarButton',
          objectId: 'TopAppBarItem',
          action: 'Click',
          actionContext: 'VisibilityIconClick',
          actionContextValue: showSensitiveInfo
            ? 'sensitiveInfoOff'
            : 'sensitiveInfoOn',
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          timestamp: Date.now(),
        }),
      )
    }
    dispatch(toggleShowSensitiveInfo())
  }

  const handleAlertsClick = () => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'TopAppBar',
          object: 'TopAppBarButton',
          objectId: 'TopAppBarItem',
          action: 'Click',
          actionContext: 'AlertsIconClick',
          actionContextValue: 'alertsDialogOpen',
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          timestamp: Date.now(),
        }),
      )
    }
    if (isMobile) {
      dispatch(focusAlert())
    } else {
      dispatch(alertsOpen())
    }
  }

  return (
    <Grid item md={4}>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item>
          <ManageWidgets />
        </Grid>

        <Grid item>
          <Tooltip
            title={
              <Typography variant="subtitle2">
                Show/Hide Privacy Items
              </Typography>
            }
            placement="top"
            arrow
          >
            <IconButton
              edge="start"
              className={classes.rightIconSpacer}
              color="inherit"
              aria-label="Show/Hide Privacy Items"
              onClick={handlePrivacyClick}
            >
              {showSensitiveInfo ? (
                <Visibility className={classes.appBarIcon} />
              ) : (
                <VisibilityOff className={classes.appBarIcon} />
              )}
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip
            title={
              <Typography variant="subtitle2">My Notifications</Typography>
            }
            placement="top"
            arrow
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Notification Alerts" // Find what index alerts is and set is as focused widget
              onClick={handleAlertsClick}
            >
              <Badge
                overlap="circular"
                badgeContent={activeAlerts}
                color="primary"
                className={classes.badgeStyle}
              >
                <NotificationsIcon className={classes.appBarIcon} />
              </Badge>
            </IconButton>
          </Tooltip>
          <AlertDialog open={dialogOpen} classes={classes} />
        </Grid>
        <Grid item>
          {isMobile === false ? <SearchDialog /> : <SearchDialogMobileView />}
        </Grid>
        {isMobile && (
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Settings Menu"
              onClick={() => history.push('/settings')}
            >
              <SettingsIcon className={classes.appBarIcon} />
            </IconButton>
          </Grid>
        )}
        <LogOutButton
          isMobile={isMobile}
          dispatch={dispatch}
          userProfile={userProfile}
          classes={classes}
        />
      </Grid>
    </Grid>
  )
}

const defaultAlert = {
  id: '',
  datePosted: '',
  dateAcknowledged: '',
  from: '',
  title: '',
  message: '',
  active: '',
}

function AlertDialog({ open, classes }) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyAlertsData)

  const [alert, setAlert] = React.useState(defaultAlert)
  React.useEffect(() => {
    if (
      localStorage.getItem('user') &&
      localStorage.getItem('user') !== '"visitor"'
    )
      dispatch(fetchMyAlerts())
  }, [])

  const dialogHandler = (openAlert = defaultAlert, acknowledge = false) => {
    if (acknowledge) {
      dispatch(acknowledgeAlert(alert))
    }
    setAlert(openAlert)
  }

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.mainDialog }}
      onClose={() => dispatch(alertsClose())}
    >
      <DialogTitle>
        <Typography variant="subtitle1">Alert Center</Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.dialog}>
        <AlertGUI
          classes={classes}
          data={widgetData}
          dialogHandler={dialogHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => dispatch(alertsClose())}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
